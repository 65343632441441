import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import AppColors from './colors';

export const TAndCContainer = styled.div`
  width: 774px;
  margin: 0 auto;
  padding: 30px 0;
  @media (max-width: 980px) {
    width: 100%;
    padding: 30px;
  }
`;

export const TAndCHeader = styled.div`
  margin: 0 0 30px 0;
  padding: 0;
  font-family: 'Gotham-Bold', arial, sans-serif;
  font-weight: normal !important;
  color: #000;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1.5px;
  text-align: center;
`;

export const PageContainer = styled.div`
  width: 800px;
  display: block;
  padding: 12px;
  margin: 8px auto;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 1px 3px 8px rgb(0 0 0 / 15%);
`;

export const ThankYouHeader = styled.h2`
  color: ${(props) => props.color};
  margin: 16px 0 16px 0;
  font-size: 28px;
  font-family: Arial;
  font-weight: bold;
`;
export const ThankYouText = styled.div`
  font-family: Arial;
  font-size: 14px;
  line-height: 14px;
  color: #505050;
  margin: 16px 0 16px 0;
`;
export const ThankYouAnchor = styled.a`
  color: ${(props) => props.color};
`;

export const ThankYouContainer = styled(Container)`
  background: #fff;
  width: 100% !important;
  max-width: 100% !important;
  color: #11467b;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;

export const ThankYouInnerContainer = styled.div`
  width: 500px;
  margin: 0 auto;
  padding: 0;
  padding-top: 100px;
  background: #fff;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    padding: 40px;
  }
`;

export const ThankYouImage = styled.img`
  width: 300px;
  height: auto;
  margin: 0 0 40px 0;
  @media (max-width: 991px) {
    width: 220px;
  }
`;

export const Header = styled.h1`
  margin: 0 0 35px 0;
  font-family: 'Gotham-Black', arial, sans-serif;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 42px;
  color: #000;
  background: #f9d019;
  width: 100%;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  @media (max-width: 991px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

export const SubHeader = styled.h2`
  padding: 0;
  margin: 0 0 30px 0;
  font-family: 'Gotham-Black', arial, sans-serif;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 38px;
  color: #0d477c;
  text-align: center;
  font-weight: bold;
  @media (max-width: 991px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const ContactText = styled.p`
  font-size: 23px;
  line-height: 34px;
  margin: 22px 0;
  & a {
    text-decoration: none;
    color: #28ace2;
  }
  @media (max-width: 991px) {
    font-size: 17px;
    line-height: 26px;
  }
`;
